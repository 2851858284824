import { useState, useEffect } from "react";
import {
  example,
  placeholder,
  STATES_WEB,
  usersDefault,
  rolesDefault,
  statsDefault,
} from "./const";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import { Player } from "@lottiefiles/react-lottie-player";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import omit from "lodash/omit";
import { Textarea } from "./components/Textarea";
import JSONInput from "react-json-editor-ajrm";
import { localeEs } from "./components/Es";

function App() {
  const { enqueueSnackbar } = useSnackbar();
  function setExample() {
    (document.getElementById("textArea") as HTMLTextAreaElement).value =
      example;
  }

  const [state, setState] = useState<STATES_WEB>(STATES_WEB.INTRODUCING);
  const [json, setJson] = useState<string>("");
  const [responseStr, setResponseStr] = useState<string>("");
  const [showDownload, setShowDownload] = useState<boolean>(false);

  const [users, setUsers] = useState<any>(usersDefault);
  const [roles, setRoles] = useState<any>(rolesDefault);
  const [tasks, setTasks] = useState<any>(statsDefault);

  function checkJSON(json: string) {
    try {
      JSON.parse(json);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async function saveChanges() {
    if (!showDownload) {
      axios
        .get("http://192.168.24.8:8000/saveBBDD?wantToSave=true")
        .then((response: AxiosResponse) => {
          enqueueSnackbar("Justificación guardada en BBDD", {
            autoHideDuration: 5000,
            variant: "success",
          });
          setShowDownload(true);
        })
        .catch((reason: any) => {});
    } else {
      downloadCSV(null);
    }
  }

  async function launchAlgorithm(jsonParameter: string | null) {
    if (jsonParameter === null) {
      jsonParameter = json;
    }
    if (checkJSON(jsonParameter)) {
      setState(STATES_WEB.LOADING);
      setShowDownload(false);
      axios
        .post("http://192.168.24.8:8000/justificacion", jsonParameter, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response: AxiosResponse) => {
          setState(STATES_WEB.RESULTS);
          setResponseStr(response.data);
        })
        .catch((reason: AxiosError) => {
          setState(STATES_WEB.INTRODUCING);
          //@ts-ignore
          enqueueSnackbar(`ERROR: ${reason.response.data.detail}`, {
            autoHideDuration: 5000,
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar("Hay un problema en el input", {
        autoHideDuration: 5000,
        variant: "error",
      });
    }
  }

  function deleteProject(project_name: string) {
    axios
      .get(
        `http://192.168.24.8:8000/download${
          project_name !== null ? "/" + project_name : ""
        }`,
        {
          responseType: "blob",
        }
      )
      .then((response: AxiosResponse) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute(
          "download",
          project_name !== null ? project_name + ".csv" : "allProjects.csv"
        );

        document.body.appendChild(link);

        link.click();

        link.remove();
        axios
          .delete("http://192.168.24.8:8000/projects/" + project_name)
          .then((value: AxiosResponse) => {
            enqueueSnackbar(
              `Eliminado proyecto ${project_name}. Se va ha descargar una backup`,
              {
                autoHideDuration: 5000,
                variant: "success",
              }
            );
          })
          .catch((reason: any) => {
            enqueueSnackbar(
              `Ha habido un problema al eliminar ${project_name}`,
              {
                autoHideDuration: 5000,
                variant: "error",
              }
            );
          });
      })
      .catch((reason: any) => {});
  }

  function downloadCSV(project_name: string | null) {
    axios
      .get(
        `http://192.168.24.8:8000/download${
          project_name !== null ? "/" + project_name : ""
        }`,
        {
          responseType: "blob",
        }
      )
      .then((response: AxiosResponse) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute(
          "download",
          project_name !== null ? project_name + ".csv" : "allProjects.csv"
        );

        document.body.appendChild(link);

        link.click();

        link.remove();
      })
      .catch((reason: any) => {});
  }

  const [projects, setProjects] = useState<{}>({});

  function refreshProjects() {
    axios
      .get("http://192.168.24.8:8000/getListProjects")
      .then((response: AxiosResponse) => {
        setProjects(response.data);
      })
      .catch((reason: any) => {});
  }

  useEffect(() => {
    if (state === STATES_WEB.PROJECTS) refreshProjects();
  }, [state]);

  return (
    <div className="">
      <header className="mb-4">
        <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <a href="./" className="flex items-center">
              <img
                src="./images/logoImas.svg"
                className="mr-3 h-6 sm:h-9"
                alt="Flowbite Logo"
              />
              <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                Algoritmo Justificación
              </span>
            </a>
            <div
              className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
              id="mobile-menu-2"
            >
              <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <a
                    onClick={() => setState(STATES_WEB.INTRODUCING)}
                    className={`cursor-pointer block py-2 pr-4 pl-3 hover:text-white ${
                      state === STATES_WEB.INTRODUCING
                        ? "text-white"
                        : "text-gray-400"
                    } lg:p-0`}
                  >
                    Inicio
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => setState(STATES_WEB.PROJECTS)}
                    className={`cursor-pointer block py-2 pr-4 pl-3 hover:text-white ${
                      state === STATES_WEB.PROJECTS
                        ? "text-white"
                        : "text-gray-400"
                    } lg:p-0 `}
                  >
                    Proyectos
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => downloadCSV(null)}
                    className={`cursor-pointer block py-2 pr-4 pl-3 text-gray-400 hover:text-white lg:p-0`}
                  >
                    Descargar CSV Completo
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {state === STATES_WEB.INTRODUCING ? (
        <>
          <div
            className="w-full px-8 pb-8 grid"
            style={{ gridTemplateColumns: "0.6fr 0.4fr" }}
          >
            {/* USERS SELECT */}
            <div className="w-full px-8 pb-8">
              <h1 className="text-center">USUARIOS ELEGIBLES</h1>
              <div className="grid grid-cols-5 border-solid border border-black content-center divide-x divide-y">
                <input id="newID" className="text-center" placeholder="ID" />
                <input
                  id="newClockify"
                  className="text-center uppercase"
                  placeholder="ID Clockify"
                />
                <input
                  id="newEmail"
                  className="text-center"
                  placeholder="Email"
                />
                <input
                  id="newName"
                  className="text-center"
                  placeholder="Nombre Completo"
                />
                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      let idUser = (
                        document.getElementById("newID") as HTMLInputElement
                      ).value.toUpperCase();
                      if (idUser === "") {
                        enqueueSnackbar(`No se puede añadir el usuario vacío`, {
                          autoHideDuration: 5000,
                          variant: "error",
                        });
                        return;
                      } else if (users[idUser]) {
                        enqueueSnackbar(
                          `El usuario ${idUser} ya está en la lista`,
                          {
                            autoHideDuration: 5000,
                            variant: "error",
                          }
                        );
                        return;
                      }
                      setUsers({
                        ...users,
                        [idUser]: [
                          (
                            document.getElementById(
                              "newClockify"
                            ) as HTMLInputElement
                          ).value,
                          (
                            document.getElementById(
                              "newEmail"
                            ) as HTMLInputElement
                          ).value,
                          (
                            document.getElementById(
                              "newName"
                            ) as HTMLInputElement
                          ).value,
                        ],
                      });
                    }}
                    className="bg-green-500 px-2 rounded-md text-white my-2"
                  >
                    AÑADIR
                  </button>
                </div>
              </div>
              <div className="mt-2 grid grid-cols-5 border-solid border border-black content-center divide-x divide-y">
                <h1 className="text-center">ID</h1>
                <h1 className="text-center">ID CLOCKIFY</h1>
                <h1 className="text-center">EMAIL</h1>
                <h1 className="text-center">NOMBRE COMPLETO</h1>
                <h1 className="text-center">ELIMINAR</h1>
                {Object.entries(users).map((value: [string, any]) => {
                  return (
                    <>
                      <h1 className="text-center text-sm font-normal">
                        {value[0]}
                      </h1>
                      <h1 className="text-center text-sm font-normal">
                        {value[1][0]}
                      </h1>
                      <h1 className="text-center text-sm font-normal">
                        {value[1][1]}
                      </h1>
                      <h1 className="text-center text-sm font-normal">
                        {value[1][2]}
                      </h1>
                      <div className="flex justify-center ">
                        <button
                          onClick={() => {
                            setUsers(omit(users, value[0]));
                          }}
                          className="bg-red-500 px-2 rounded-md text-white"
                        >
                          ELIMINAR
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {/* ROLES SELECT */}
            <div className="w-fit px-8 pb-8">
              <h1 className="text-center">ROLES DISPONIBLES</h1>
              <div className="grid grid-cols-2 border-solid border border-black content-center divide-x divide-y">
                <input
                  id="newRol"
                  placeholder="Nombre del rol"
                  className="uppercase"
                />
                <button
                  onClick={() => {
                    let newRol = (
                      document.getElementById("newRol") as HTMLInputElement
                    ).value.toUpperCase();
                    setRoles({ ...roles, [newRol]: [] });
                  }}
                  className="bg-green-500 px-2 rounded-md text-white my-2 w-1/2 mx-auto"
                >
                  AÑADIR
                </button>
              </div>
              <div className="mt-2 grid grid-cols-2 border-solid border border-black content-center divide-x divide-y">
                {Object.entries(roles).map((value: [string, any]) => {
                  return (
                    <>
                      <h1>{value[0]}</h1>
                      <div className="p-2">
                        {value[1].map((userRol: any) => {
                          return (
                            <div className="flex w-full justify-between">
                              <h1>{userRol}</h1>
                              <button
                                onClick={() => {
                                  setRoles({
                                    ...roles,
                                    [value[0]]: roles[value[0]].filter(
                                      (item: any) => item !== userRol
                                    ),
                                  });
                                }}
                              >
                                X
                              </button>
                            </div>
                          );
                        })}
                        <div className="flex justify-center gap-2">
                          <input
                            className="border rounded-md px-2"
                            id={`input_${value[0]}`}
                            placeholder="Añadir..."
                          />
                          <button
                            className=""
                            onClick={() => {
                              let rol = value[0];
                              let newUserToRol = (
                                document.getElementById(
                                  "input_" + value[0]
                                ) as HTMLInputElement
                              ).value.toUpperCase();
                              if (users[newUserToRol] === undefined) {
                                enqueueSnackbar(
                                  `El usuario ${newUserToRol} no está en la lista de usuarios`,
                                  {
                                    autoHideDuration: 5000,
                                    variant: "error",
                                  }
                                );
                                return;
                              }
                              setRoles({
                                ...roles,
                                [value[0]]: [...roles[rol], newUserToRol],
                              });
                              (
                                document.getElementById(
                                  "input_" + value[0]
                                ) as HTMLInputElement
                              ).value = "";
                            }}
                          >
                            <img className="w-5 h-5" src="./images/add.svg" />
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          {/* TASK SELECT */}
          <div className="w-full px-8">
            <h1 className="text-center">LISTA TAREAS</h1>
            <div className="grid grid-cols-5 border-solid border border-black content-center divide-x divide-y">
              <input
                id="newTask"
                className="text-center uppercase"
                placeholder="Nueva tarea"
              />
              <input
                id="newStart"
                className="text-center"
                placeholder="Start: YYYY-MM-DDTHH:MM:SSZ"
              />
              <input
                id="newEnd"
                className="text-center uppercase"
                placeholder="End: YYYY-MM-DDTHH:MM:SSZ"
              />
              <div></div>
              <div className="flex justify-center">
                <button
                  onClick={() => {
                    let idTask = (
                      document.getElementById("newTask") as HTMLInputElement
                    ).value.toUpperCase();
                    if (idTask === "") {
                      enqueueSnackbar(`No se puede añadir la tarea vacia`, {
                        autoHideDuration: 5000,
                        variant: "error",
                      });
                      return;
                    }
                    setTasks({
                      ...tasks,
                      [idTask]: {
                        start: (
                          document.getElementById(
                            "newStart"
                          ) as HTMLInputElement
                        ).value,
                        end: (
                          document.getElementById("newEnd") as HTMLInputElement
                        ).value,
                        roles: "",
                      },
                    });
                  }}
                  className="bg-green-500 px-2 rounded-md text-white my-2"
                >
                  AÑADIR
                </button>
              </div>
            </div>
            <div className="mt-2 grid grid-cols-5 border-solid border border-black content-center divide-x divide-y">
              <h1 className="text-center">NOMBRE TAREA</h1>
              <h1 className="text-center">INICIO</h1>
              <h1 className="text-center">FINAL</h1>
              <h1 className="text-center">ROLES</h1>
              <h1 className="text-center">ELIMINAR</h1>
              {Object.entries(tasks).map((value: [string, any]) => {
                return (
                  <>
                    <h1 className="text-center">{value[0]}</h1>
                    <h1 className="text-center">{value[1].start}</h1>
                    <h1 className="text-center">{value[1].end}</h1>
                    <div>
                      {Object.entries(value[1].roles).map(
                        (value: [string, any]) => {
                          return (
                            <>
                              <div className="flex">
                                <h1>{value[0]}:</h1>
                                <h1>{value[1]}</h1>
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                    <div className="flex justify-center ">
                      <button
                        onClick={() => {
                          setTasks(omit(tasks, value[0]));
                        }}
                        className="bg-red-500 px-2 my-2 h-fit rounded-md text-white"
                      >
                        ELIMINAR
                      </button>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="w-full flex justify-center mt-8">
            <button
              onClick={() => {
                setJson(
                  JSON.stringify({
                    ProjectName: "VIRTUAL FYS",
                    Users: users,
                    Roles: roles,
                    Tasks: tasks,
                  })
                );
                launchAlgorithm(
                  JSON.stringify({
                    ProjectName: "VIRTUAL FYS",
                    Users: users,
                    Roles: roles,
                    Tasks: tasks,
                  })
                );
              }}
              className="bg-green-500 text-white w-1/3 p-2 rounded-lg hover:bg-green-700"
            >
              LANZAR SIMULACIÓN
            </button>
          </div>
          <div className="w-full px-8 pb-8 mt-12">
            <h1 className="text-center text-2xl">O escribe aquí el JSON...</h1>
            <div className="w-full border rounded-lg bg-gray-700 border-gray-600">
              <div className="px-4 py-2 rounded-t-lg bg-gray-800">
                <textarea
                  id="textArea"
                  className="resize-none w-full h-[65vh] px-0 text-sm font-thin border-0 bg-gray-800 focus:ring-0 text-white placeholder-gray-400"
                  placeholder={placeholder}
                  required
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setJson(event.target.value);
                  }}
                ></textarea>
              </div>
              <div className="flex items-center justify-end gap-3 px-3 py-2 border-t dark:border-gray-600">
                <button
                  onClick={() => {
                    setExample();
                    setJson(example);
                  }}
                  className="inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white  rounded-lg  hover:underline"
                >
                  Rellenar ejemplo
                </button>
                <button
                  onClick={() => {
                    launchAlgorithm(null);
                  }}
                  className="reel-button inline-flex items-center py-2.5 px-4 text-md font-medium text-center text-white bg-green-600 rounded-lg  hover:bg-green-700"
                >
                  Lanzar simulación
                </button>
              </div>
            </div>
          </div>
        </>
      ) : state === STATES_WEB.LOADING ? (
        <div className="w-full h-full flex flex-col justify-center items-center my-4">
          <Player
            autoplay
            loop
            src="./lottie/calculating.json"
            className="w-1/2"
          ></Player>
          <h1 className="text-center font-black md:text-2xl uppercase">
            CALCULANDO...
          </h1>
        </div>
      ) : state === STATES_WEB.RESULTS ? (
        <div>
          <div className="bg-black rounded-md m-8 mb-4">
            <div className="flex items-center relative text-gray-200 bg-gray-800 px-4 py-2 text-xs font-sans justify-between rounded-t-md">
              <h1 className="font-normal text-lg">Resultados</h1>
              <div className="flex flex-row gap-4 h-[3rem]">
                <button
                  onClick={() => launchAlgorithm(null)}
                  className="flex ml-auto bg-red-600 p-3 text-base rounded-md hover:bg-red-700"
                >
                  Volver a intentar
                </button>
                <button
                  id="saveChanges"
                  onClick={() => saveChanges()}
                  className="flex justify-center items-center ml-auto w-[10rem] bg-green-600 p-3 text-base rounded-md hover:bg-green-700"
                >
                  {showDownload ? (
                    <img
                      src="./images/csv.svg"
                      className="cursor-pointer transform h-full hover:scale-110"
                    />
                  ) : (
                    "Guardar en BBDD"
                  )}
                </button>
              </div>
            </div>
            <div className="p-4 overflow-y-auto">
              <code className="text-white whitespace-pre-line">
                {responseStr}
              </code>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-3 gap-3 p-4">
            {Object.entries(projects).map((project) => {
              return (
                <div
                  key={project[0]}
                  className=" p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                >
                  <a href="#">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {project[0]}
                    </h5>
                  </a>
                  <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    {
                      //@ts-ignore
                      `Horas totales: ${project[1]?.totalDuration}`
                    }
                  </p>
                  <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    {
                      //@ts-ignore
                      `Última actualización: ${project[1]?.lastUpdate}`
                    }
                  </p>
                  <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    {
                      //@ts-ignore
                      `Primer time entry: ${project[1]?.firstTimeEntry}`
                    }
                  </p>
                  <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    {
                      //@ts-ignore
                      `Último time entry: ${project[1]?.lastTimeEntry}`
                    }
                  </p>
                  <a
                    onClick={() => downloadCSV(project[0])}
                    className="cursor-pointer inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg"
                  >
                    Descargar CSV
                  </a>
                  <button
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      // let button = event.target as HTMLButtonElement;
                      let clientSureDelete = window.confirm(
                        "Vas a eliminar la justificación de la Base de Datos. ¿Estás seguro de que quieres hacerlo?"
                      );
                      if (clientSureDelete) {
                        setProjects(omit(projects, project[0]));
                        deleteProject(project[0]);
                      }
                    }}
                    className="ml-4 cursor-pointer inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg disabled:opacity-25"
                  >
                    Eliminar proyecto
                  </button>
                </div>
              );
              return JSON.stringify(project);
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default App;
