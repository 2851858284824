export enum STATES_WEB {
  INTRODUCING,
  LOADING,
  RESULTS,
  PROJECTS,
}

export const rolesDefault = {
  GESTION: ["PETER", "SERGIO"],
  "3D": ["CARLOS", "SERGIO", "JAVIER"],
  "2D": ["LAURA", "ELISA", "JAVIER"],
  DEVELOPER: ["SHERRERO", "CIRES", "DANIEL", "PRIETO"],
};

export const usersDefault = {
  ANA: ["60f6b902f547ce2601b0e3f2", "ana@imascono.com", "Ana"],
  CARLOS: ["60895fb8c22a255021197d7e", "carlos@imascono.com", "Carlos "],
  CARMONA: [
    "634d2b1d61d1940b39191862",
    "dcarmona@imascono.com",
    "David Carmona",
  ],
  CIRES: ["5dad62bce8cd9c50073ae988", "cires@imascono.com", "Daniel Cires"],
  DANIEL: ["60251bd4158b7e35c8bff247", "daniel@imascono.com", "Daniel"],
  DAVID: ["628dbe0748035d1fe07ba047", "david@imascono.com", "David"],
  ELISA: ["5dad62d121b3d66467e77389", "elisa@imascono.com", "Elisa Amoros "],
  FERNANDO: ["6051c2778914314698751abe", "fernando@imascono.com", "Fernando"],
  FRAN: ["5fc9dee197b6795a8e898f4e", "fran@imascono.com", "Fran Pérez"],
  HECTOR: ["5db28e6421b3d66467ebceb7", "hp@imascono.com", "Hector Paz"],
  JAVIER: [
    "630f07e1cb18da61cfd39f44",
    "javier@imascono.com",
    "Javier Vivancos",
  ],
  JUANCARLOS: [
    "5dbffc7f0ae62d760486a471",
    "administration@imascono.com",
    "Juan Carlos",
  ],
  LAURA: ["60251bd4158b7e35c8bff248", "laura@imascono.com", "Laura"],
  OSCAR: ["6051c2778914314698751abf", "oscar@imascono.com", "Óscar Rubio"],
  PAOLA: ["633aad71714fc93911d2563a", "paola@imascono.com", "Paola Pequerul"],
  PRIETO: ["633ad87e5eca38568762b012", "dprieto@imascono.com", "David Prieto"],
  PETER: ["5dad63115e326c429dc4cec7", "peter@imascono.com", "Pedro Lozano"],
  RAMON: ["6180f4d023b4ce611cf2249f", "ramon@imascono.com", "Ramón"],
  RAUL: ["5e14665f1cda2a266978f675", "raul@imascono.com", "Raúl Romo"],
  SANTA: [
    "5c9a2172f15c980c7be67443",
    "santamaria@imascono.com",
    "Sergio Santamaria",
  ],
  SERGIO: ["5dad62f7e8cd9c50073aea18", "sergio@imascono.com", "Sergio García"],
  SHERRERO: ["60c32b267a48f36236ed40b1", "sherrero@imascono.com", "SergioH"],
  VICTOR: ["61f3a154ac897025894a6eff", "victor@imascono.com", "Victor"],
  YAIZA: ["6315ddcf41adb133ce2fc1de", "yaiza@imascono.com", "Yaiza"],
};

export const statsDefault = {
  "O1 - Pedagogial framework: Sala 1 Gamificación": {
    start: "2022-09-01T06:00:00.998Z",
    end: "2022-09-30T19:00:00.998Z",
    roles: {
      GESTION: 163,
      DEVELOPER: 80,
    },
  },
  "O1 - Pedagogial framework: Sala 2 Gamificación": {
    start: "2022-10-01T06:00:00.998Z",
    end: "2022-10-31T19:00:00.998Z",
    roles: {
      GESTION: 138,
      DEVELOPER: 80,
    },
  },
  "O1 - Pedagogial framework: Sala 3 Gamificación": {
    start: "2022-11-01T06:00:00.998Z",
    end: "2022-11-30T19:00:00.998Z",
    roles: {
      GESTION: 190,
      DEVELOPER: 80,
    },
  },
  "O1 - Pedagogial framework: Sala 4 Gamificación": {
    start: "2022-12-01T06:00:00.998Z",
    end: "2022-12-31T19:00:00.998Z",
    roles: {
      GESTION: 153,
      DEVELOPER: 100,
    },
  },
  "O3 - Design of virtual spaces: Plataforma de Gestión de Contenidos": {
    start: "2022-11-01T06:00:00.998Z",
    end: "2023-01-31T19:00:00.998Z",
    roles: {
      DEVELOPER: 600,
    },
  },
  "O3 - Design of virtual spaces: Diseño y Desarrollo Sala 1": {
    start: "2022-02-01T06:00:00.998Z",
    end: "2022-03-31T19:00:00.998Z",
    roles: {
      GESTION: 40,
      "3D": 200,
      DEVELOPER: 200,
      "2D": 20,
    },
  },
  "O3 - Design of virtual spaces: Diseño y Desarrollo Sala 2": {
    start: "2022-04-01T06:00:00.998Z",
    end: "2022-05-31T19:00:00.998Z",
    roles: {
      GESTION: 40,
      "3D": 200,
      DEVELOPER: 200,
      "2D": 20,
    },
  },
  "O3 - Design of virtual spaces: Diseño y Desarrollo Sala 3": {
    start: "2022-06-01T06:00:00.998Z",
    end: "2022-07-31T19:00:00.998Z",
    roles: {
      "3D": 200,
      DEVELOPER: 200,
      "2D": 60,
    },
  },
  "O3 - Design of virtual spaces: Diseño y Desarrollo Sala 4": {
    start: "2022-08-01T06:00:00.998Z",
    end: "2022-09-30T19:00:00.998Z",
    roles: {
      "3D": 170,
      DEVELOPER: 250,
      "2D": 90,
    },
  },
  "O3 - Design of virtual spaces: Diseño y Desarrollo Sala 5": {
    start: "2022-10-01T06:00:00.998Z",
    end: "2022-11-30T19:00:00.998Z",
    roles: {
      "3D": 30,
      DEVELOPER: 165,
      "2D": 125,
    },
  },
  "O4 - Online platform: Definición de Proyecto": {
    start: "2022-01-01T06:00:00.998Z",
    end: "2022-03-31T19:00:00.998Z",
    roles: {
      GESTION: 400,
      "2D": 100,
    },
  },
  "O4 - Online platform: Plataforma Online": {
    start: "2022-10-01T06:00:00.998Z",
    end: "2023-02-24T19:00:00.998Z",
    roles: {
      "2D": 510,
    },
  },
};

export const example = `{
  "ProjectName": "VIRTUAL FYS",
  "Users": {
    "ANA": ["60f6b902f547ce2601b0e3f2", "ana@imascono.com", "Ana"],
    "CARLOS": ["60895fb8c22a255021197d7e", "carlos@imascono.com", "Carlos "],
    "CARMONA": ["634d2b1d61d1940b39191862","dcarmona@imascono.com", "David Carmona"],
    "CIRES": ["5dad62bce8cd9c50073ae988", "cires@imascono.com", "Daniel Cires"],
    "DANIEL": ["60251bd4158b7e35c8bff247", "daniel@imascono.com", "Daniel"],
    "DAVID": ["628dbe0748035d1fe07ba047", "david@imascono.com", "David"],
    "ELISA": ["5dad62d121b3d66467e77389", "elisa@imascono.com", "Elisa Amoros "],
    "FERNANDO": ["6051c2778914314698751abe","fernando@imascono.com", "Fernando"],
    "FRAN": ["5fc9dee197b6795a8e898f4e", "fran@imascono.com", "Fran Pérez"],
    "HECTOR": ["5db28e6421b3d66467ebceb7", "hp@imascono.com", "Hector Paz"],
    "JAVIER": ["630f07e1cb18da61cfd39f44","javier@imascono.com", "Javier Vivancos"],
    "JUANCARLOS": ["5dbffc7f0ae62d760486a471","administration@imascono.com", "Juan Carlos"],
    "LAURA": ["60251bd4158b7e35c8bff248", "laura@imascono.com", "Laura"],
    "OSCAR": ["6051c2778914314698751abf", "oscar@imascono.com", "Óscar Rubio"],
    "PAOLA": ["633aad71714fc93911d2563a","paola@imascono.com", "Paola Pequerul"],
    "PRIETO": ["633ad87e5eca38568762b012","dprieto@imascono.com", "David Prieto"],
    "PETER": ["5dad63115e326c429dc4cec7", "peter@imascono.com", "Pedro Lozano"],
    "RAMON": ["6180f4d023b4ce611cf2249f", "ramon@imascono.com", "Ramón"],
    "RAUL": ["5e14665f1cda2a266978f675", "raul@imascono.com", "Raúl Romo"],
    "SANTA": ["5c9a2172f15c980c7be67443","santamaria@imascono.com", "Sergio Santamaria"],
    "SERGIO": ["5dad62f7e8cd9c50073aea18","sergio@imascono.com", "Sergio García"],
    "SHERRERO": ["60c32b267a48f36236ed40b1", "sherrero@imascono.com", "SergioH"],
    "VICTOR": ["61f3a154ac897025894a6eff", "victor@imascono.com", "Victor"],
    "YAIZA": ["6315ddcf41adb133ce2fc1de", "yaiza@imascono.com", "Yaiza"]
    },
    "Roles":{
        "GESTION": ["PETER","SERGIO"],
        "3D": ["CARLOS", "SERGIO", "JAVIER"],
        "2D": ["LAURA", "ELISA", "JAVIER"],
        "DEVELOPER": ["SHERRERO", "CIRES", "DANIEL", "PRIETO"]
    },
    "Tasks":{
        "O1 - Pedagogial framework: Sala 1 Gamificación": {
            "start": "2022-09-01T06:00:00.998Z",
            "end": "2022-09-30T19:00:00.998Z",
            "roles": {
                "GESTION": 163,
                "DEVELOPER": 80
            }
        },
        "O1 - Pedagogial framework: Sala 2 Gamificación": {
          "start": "2022-10-01T06:00:00.998Z",
          "end": "2022-10-31T19:00:00.998Z",
          "roles": {
              "GESTION": 138,
              "DEVELOPER": 80
          }
        },
        "O1 - Pedagogial framework: Sala 3 Gamificación": {
          "start": "2022-11-01T06:00:00.998Z",
          "end": "2022-11-30T19:00:00.998Z",
          "roles": {
              "GESTION": 190,
              "DEVELOPER": 80
          }
        },
        "O1 - Pedagogial framework: Sala 4 Gamificación": {
          "start": "2022-12-01T06:00:00.998Z",
          "end": "2022-12-31T19:00:00.998Z",
          "roles": {
              "GESTION": 153,
              "DEVELOPER": 100
          }
        },
        "O3 - Design of virtual spaces: Plataforma de Gestión de Contenidos": {
          "start": "2022-11-01T06:00:00.998Z",
          "end": "2023-01-31T19:00:00.998Z",
          "roles": {
              "DEVELOPER": 600
          }
        },
        "O3 - Design of virtual spaces: Diseño y Desarrollo Sala 1": {
          "start": "2022-02-01T06:00:00.998Z",
          "end": "2022-03-31T19:00:00.998Z",
          "roles": {
               "GESTION": 40,
               "3D": 200,
               "DEVELOPER": 200,
               "2D": 20
          }
        },
        "O3 - Design of virtual spaces: Diseño y Desarrollo Sala 2": {
          "start": "2022-04-01T06:00:00.998Z",
          "end": "2022-05-31T19:00:00.998Z",
          "roles": {
               "GESTION": 40,
               "3D": 200,
               "DEVELOPER": 200,
               "2D": 20
          }
        },
        "O3 - Design of virtual spaces: Diseño y Desarrollo Sala 3": {
          "start": "2022-06-01T06:00:00.998Z",
          "end": "2022-07-31T19:00:00.998Z",
          "roles": {
               "3D": 200,
               "DEVELOPER": 200,
               "2D": 60
          }
        },
        "O3 - Design of virtual spaces: Diseño y Desarrollo Sala 4": {
          "start": "2022-08-01T06:00:00.998Z",
          "end": "2022-09-30T19:00:00.998Z",
          "roles": {
               "3D": 170,
               "DEVELOPER": 250,
               "2D": 90
          }
        },
        "O3 - Design of virtual spaces: Diseño y Desarrollo Sala 5": {
          "start": "2022-10-01T06:00:00.998Z",
          "end": "2022-11-30T19:00:00.998Z",
          "roles": {
               "3D": 30,
               "DEVELOPER": 165,
               "2D": 125
          }
        },
        "O4 - Online platform: Definición de Proyecto": {
          "start": "2022-01-01T06:00:00.998Z",
          "end": "2022-03-31T19:00:00.998Z",
          "roles": {
               "GESTION": 400,
               "2D": 100
          }
        },
        "O4 - Online platform: Plataforma Online": {
          "start": "2022-10-01T06:00:00.998Z",
          "end": "2023-02-24T19:00:00.998Z",
          "roles": {
               "2D": 510
          }
        }
    }      
}

`; // GESTION, 3D, 2D, DEVELOPER, QA

export const placeholder = `{
    "Users": {
      "NOMBRE_1": [<"idClockify">, <"email">, <"Nombre Completo">],
      "NOMBRE_2": [<"idClockify">, <"email">, <"Nombre Completo">],
      "NOMBRE_N": [<"idClockify">, <"email">, <"Nombre Completo">]
    },
    "Roles": {
      "ROL_1": [<"NOMBRE_1">, <"NOMBRE_2">, <"NOMBRE_N">],
      "ROL_2": [<"NOMBRE_1">, <"NOMBRE_2">, <"NOMBRE_N">],
      "ROL_N": [<"NOMBRE_1">, <"NOMBRE_2">, <"NOMBRE_N">]
    },
    "Tasks": {
      "Task_1": {
        "start": "<YYYY>-<MM>-<DD>T<HH>:<MM>:<SS>Z",
        "end": "<YYYY>-<MM>-<DD>T<HH>:<MM>:<SS>Z",
        "roles": {
          "ROL_1": <Numero de horas>,
          "ROL_2": <Numero de horas>,
          "ROL_N": <Numero de horas>,
        }
      },
      "Task_2": {
        "start": "<YYYY>-<MM>-<DD>T<HH>:<MM>:<SS>Z",
        "end": "<YYYY>-<MM>-<DD>T<HH>:<MM>:<SS>Z",
        "roles": {
          "ROL_1": <Numero de horas>,
          "ROL_2": <Numero de horas>,
          "ROL_N": <Numero de horas>,
        }
      },
      "Task_N": {
        "start": "<YYYY>-<MM>-<DD>T<HH>:<MM>:<SS>Z",
        "end": "<YYYY>-<MM>-<DD>T<HH>:<MM>:<SS>Z",
        "roles": {
          "ROL_1": <Numero de horas>,
          "ROL_2": <Numero de horas>,
          "ROL_N": <Numero de horas>,
        }
      },
    }
  }
  
  `;
